import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, IPhone, img } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p className="leading-9">
  There are literally 100s of changes and commits to the codebase every week.
  Some are bug fixes, some are tweaks to the user interface, some are new
  features, some are code quality changes, some are test code. A lot more than
  you will find interresting to know about, but I will try to keep a track of
  new features and significant changes of the app that might affect you as a
  user here.
    </p>
    <br />
    <h4>{`16. may 2024`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Feature: Added support for creating your own lists of trails, sort of like a playlist
for trails. You can mark trails as ridden or add them to your favorites. You can create
your own custom lists where you can collect trails for any purpose.`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Current features: Mark a trail as ridden, add a trail to your favorites, create
custom lists.`}</li>
          <li parentName="ul">{`Planned features: Public and private lists, custom naming of trails in a list,
custom sorting of trails, reversal of tracks, sharing of lists, download a list
as a single GPX-file, show the content of a list on the map, list which lists a
particular trail is part of (and more).`}</li>
        </ul>
      </li>
    </ul>
    <h4>{`3. may 2024`}</h4>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Feature: You can now show the surface type of a trail in the trail description.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Feature: You can show the elevation of the track as a gradient along the track.`}</p>
      </li>
    </ul>
    <h4>{`15. february 2024`}</h4>
    <ul>
      <li parentName="ul">{`Feature: You can now filter and sort trails on difficulty, length, ascent, descent, altitude,
reviews, and more. This will make it easier to find trails that match your preferences.`}</li>
    </ul>
    <h4>{`23. january 2024`}</h4>
    <ul>
      <li parentName="ul">{`UX: Expanded the search results so it will return natural features and points of
interest such as mountains, lakes, and other named locations in addition to places.`}</li>
    </ul>
    <h4>{`1. december 2023`}</h4>
    <ul>
      <li parentName="ul">{`Feature: Added a separate winter trail biking category so you can discover prepped
trails on snow and take your mountain bike for a spin during the winter. If you have
your own prepped snow trails, we invite you to add them to Trailguide.`}</li>
    </ul>
    <h4>{`28. july 2023`}</h4>
    <ul>
      <li parentName="ul">{`Feature/UX: When you click on the user image in an activity page you open the profile
page of that user. That page has now been improved visually as well as added support
for searching, filtering, and sorting.`}</li>
      <li parentName="ul">{`Feature/UX: When you click on the "Nearby" tab for an activity that page has also
been improved with added support for searching, filtering, and sorting. The nearby
activities are now sorted by the closest first.`}</li>
    </ul>
    <h4>{`24. july 2023`}</h4>
    <ul>
      <li parentName="ul">{`Feature: Added support to log in using your google account`}</li>
      <li parentName="ul">{`Feature: Added support to use .fit garmin files to add new trails`}</li>
    </ul>
    <h4>{`8. july 2023`}</h4>
    <ul>
      <li parentName="ul">{`Feature: Added support to mark condition report problems such as fallen trees as resolve
by any logged-in user. So if you see a warning triangle on the map, but the problem
seems to be gone, you can now mark it as resolved.`}</li>
    </ul>
    <h4>{`9. june 2023`}</h4>
    <ul>
      <li parentName="ul">{`Feature: Added support to remove your own condition reports if you need to do this.`}</li>
    </ul>
    <h4>{`7. june 2023`}</h4>
    <ul>
      <li parentName="ul">{`Feature: You can now report the position of any problems if you report about a fallen
tree, broken bridge, or other problems on the trail. This will help other users and
trail maintainers to see any problems so it can be fixed as soon as possible.`}</li>
    </ul>
    <h4>{`6. june 2023`}</h4>
    <ul>
      <li parentName="ul">{`UX: Combined the show list/map buttons into one so you now toggle between the list
view and map view when clicking the button. This made space for the transparency button
in the middle of the navigation bar so it is immediately available and easier to use.`}</li>
    </ul>
    <h4>{`21. may 2023`}</h4>
    <ul>
      <li parentName="ul">{`Feature: Added support for detailed Strava heatmap beyond zoom level 12. This really
enables you to find and navigate trails in any area of the world.`}</li>
    </ul>
    <h4>{`17. april 2023`}</h4>
    <ul>
      <li parentName="ul">{`Political: Removed the russian translation from the app and replaced it with Ukranian.`}</li>
    </ul>
    <h4>{`13. april 2023`}</h4>
    <ul>
      <li parentName="ul">{`Feature: It is now possible to have the map rotate so that the direction you are
heading is always up. This is a feature that has been requested by many users and
makes it easier to see where you are going and where to turn.`}</li>
    </ul>
    <h4>{`12. april 2023`}</h4>
    <ul>
      <li parentName="ul">{`Feature: Added breadcrumbs where you moved when you are in navigation mode. This will
make it easier to understand which way to turn when you reach a fork on the trail.`}</li>
    </ul>
    <h4>{`11. april 2023`}</h4>
    <ul>
      <li parentName="ul">{`Feature: Added a visible heading indicator to the location indicator in navigation mode.`}</li>
      <li parentName="ul">{`UX: Added the possibility to zoom using one finger. The gesture tap-tap-drag will now
zoom the map in or out. The feature needs to be enabled in the settings dialog.`}</li>
    </ul>
    <h4>{`30. march 2023`}</h4>
    <ul>
      <li parentName="ul">{`UX: The main menu and settings dialog is now combined and is accessed from the
avatar icon.`}</li>
    </ul>
    <h4>{`29. march 2023`}</h4>
    <ul>
      <li parentName="ul">{`UX: Added a content category selector below the search field instead of having this in
the navigation bar at the bottom of the screen. This makes it a lot easier to switch
content category and see which one is currently selected.`}</li>
    </ul>
    <h4>{`28. march 2023`}</h4>
    <ul>
      <li parentName="ul">{`Feature: Added Strava running, skiing, and water heatmaps.`}</li>
      <li parentName="ul">{`UX: New and much improved map and map details selector dialog`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      